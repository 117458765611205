import { graphql } from 'gatsby'
import get from 'lodash/get'
// import Img from 'gatsby-image'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'

class Shop extends React.Component {
  render() {
    const { location, data } = this.props
    const shop = get(data, 'shop.childImageSharp.fixed')

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Shop" />
        <section>
          <div className="alert alert-warning mt-5" role="alert">
            <div className="d-flex justify-content-center">
              <span>underconstruction…</span>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Shop

export const query = graphql`
  query ShopPageQuery {
    shop: file(name: { eq: "shop" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
